import axios from "axios";
import ApiUrl from '../config/ApiUrl';

class ManutencaoPesagemService {
    getManutencoes(pagina, qtdRegistro, filtros = {}, sort) {
        return axios.get(`${ ApiUrl.portal_url }api/pa/v1/manutencao-pesagem/buscar-com-paginacao`, {
            params: {
                page: pagina,
                size: qtdRegistro,
                sort: sort,
                codEstabelecimento: filtros.codEstabelecimento,
                inicio: filtros.inicio,
                termino: filtros.termino
            }
        });
    }
        
    getProducaoAgricolaPendente(filtros, pagina, pageable){        
        return axios.get(`${ ApiUrl.portal_url }api/pa/v1/manutencao-pesagem/ticket-pesagem/producao-agricola-pendente`, {
            params: {
                page: pagina,
                size: pageable,
                sort: "nroDocPesagem",
                filtrarProducaoAgricola: filtros.filtrarProducaoAgricola,
                codEstabelecimento: filtros.codEstabelecimento,
                nroDocPesagemInicio: filtros.nroDocPesagemInicio,
                nroDocPesagemFim: filtros.nroDocPesagemFim,
                tipoManutencao: filtros.tipoManutencao,
                inicio: filtros.inicio,
                termino: filtros.termino
            }
        });
    }
        
    getPesagemDiferentesDeProducaoAgricola(filtros){        
        return axios.get(`${ ApiUrl.portal_url }api/pa/v1/manutencao-pesagem/ticket-pesagem/pesagem-diferentes-producao-agricola`, {
            params: {
                filtrarProducaoAgricola: filtros.filtrarProducaoAgricola,
                codEstabelecimento: filtros.codEstabelecimento,
                nroDocPesagemInicio: filtros.nroDocPesagemInicio,
                nroDocPesagemFim: filtros.nroDocPesagemFim,
                inicio: filtros.inicio,
                termino: filtros.termino
            }
        });
    }

    salvar(data) {
        const config = {
            headers: {
                'content-type': 'application/json'
            }
        }
        return axios.post(`${ ApiUrl.portal_url }api/pa/v1/manutencao-pesagem`, data, config);
    }

    sincronizarComERP(id) {
        return axios.get(`${ ApiUrl.portal_url }api/pa/v1/manutencao-pesagem/solicitar-manutencao-erp/${id}`);
    }

}

export default new ManutencaoPesagemService();