<template>
    <ManutencaoPesagemList />
</template>

<script lang="js">
    import ManutencaoPesagemList from '../../components/atividade/ManutencaoPesagemList.vue';

    export default {
        data(){
            return {  }
        },
        methods: {
            
        },

        created() {
            
        },

        updated() {
            
        },

        computed: {
			currentUser() {
				return this.$store.state.auth.user;
			}
		},
        components: { ManutencaoPesagemList }
    }

</script>