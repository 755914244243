<template>
    <div class="card" id="pesagem-list">

        <Toast />

        <div class="flex align-items-center">
           <i class="pi pi-tablet mr-1" style="font-size: 1.4rem"></i>
           <h3 style="margin:0px 5px;">Manutenção de Pesagem</h3>
        </div>

        <hr />
        
        <DataTable 
            responsiveLayout="scroll"
            v-model:selection="manutencao"
            selectionMode="single"
            scrollDirection="both"
            showGridlines
            :value="manutencoes">

            <template #header>
                <ManutencaoPesagemFiltro
                    :totalRegistro="totalRegistro"
                    @filtrar="filtrar"
                />
            </template>

            <template #empty>
                Nenhum registro encontrado.
            </template>

            <template #loading>
                Carregando... Por favor, aguarde.
            </template>
            <Column field="data" header="Data" style="width:11.5rem;">
                <template #body="{ data }">
                    <strong>{{ formatDate(data.dataCadastro, 'DD/MM/YY HH:mm:ss')}}</strong>
                </template>
            </Column>
            <Column field="codEstabelecimento" header="Estab." style="width:5rem;"/>
            
            <Column field="usuario" header="Usuário" style="width:10rem;"/>
            
            <Column field="descricaoTipoManutencao" header="Manutenção" style="width:10rem;"/>

            <Column field="novoTipoPesagem" header="Novo tipo" style="width:10rem;">
                <template #body="{ data }">
                    {{ getDecricaoTipo(data.novoTipoPesagem) }}
                </template>
            </Column>

            <Column field="observacao" header="Observação"/>

            <Column field="transacao" header="Transação"/>

            <Column field="pesagens" header="Ticket" style="width:30rem;">
                <template #body="{ data }">
                    <DataView :value="data.pesagens">
                        <template #list="slotProps">
                            <div class="col-12">
                                <div class="flex flex-column xl:flex-row xl:align-items-start p-1 gap-4">
                                    
                                    <div class="flex flex-column sm:flex-row justify-content-between align-items-center xl:align-items-start flex-1 gap-4">
                                        <div class="flex flex-column align-items-center sm:align-items-start gap-3">
                                            <div class="text-2xl font-bold text-900">Nro: {{ slotProps.data.nroDocPesagem }}</div>
                                            <span><strong>Tipo anterior:</strong> {{ getDecricaoTipo(slotProps.data.tipoPesagem) }}</span>

                                            <span class="pt-3"><strong>Produtor:</strong> {{ slotProps.data.codProdutor }}</span>
                                            <span>{{ slotProps.data.nomeProdutor }}</span>
                                            <div class="flex align-items-center gap-3 mt-2 mb-2">
                                                <span class="flex align-items-center gap-2">
                                                    <span class="font-semibold">Situação:</span>
                                                </span>
                                                <Tag :value="getDescricaoStatus(slotProps.data.statusIntegracao)" 
                                                     :severity="getSeverity(slotProps.data.statusIntegracao)"></Tag>
                                            </div>
                                        </div>
                                        <div class="flex sm:flex align-items-center sm:align-items-end gap-3 sm:gap-2">
                                            <Button class="p-button-rounded p-button-info flex-none m-2" 
                                                icon="pi pi-info" :title="slotProps.data.mensagemIntegracao"
                                                v-if="slotProps.data.mensagemIntegracao != undefined"></Button>

                                            <Button class="p-button-rounded p-button-warning flex-none m-2" 
                                                icon="pi pi-send" title="Sincronizar com ERP" rounded 
                                                :disabled="slotProps.data.statusIntegracao == 'INTEGRADO'" 
                                                @click="sincronizar(slotProps.data.id)"></Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </DataView>
                </template>
            </Column>

        </DataTable>
        
        <Paginator
            v-model:first="firstRow"
            :rows="qtdRegistro"
            :total-records="totalRegistro"
            :rowsPerPageOptions="[5, 10, 20, 30]"
            @page="onPage"/>

            <hr />

    </div>

</template>

<script>
    import ManutencaoPesagemFiltro from './ManutencaoPesagemFiltro.vue';
    import ManutencaoPesagemService from '../../service/ManutencaoPesagemService';
    import StorageService from '../../service/StorageService';
    import Formatacao from '../../utilities/Formatacao';
    import Paginator from 'primevue/paginator';

    export default {
        name: 'ManutencaoPesagemList',
        components: { Paginator, ManutencaoPesagemFiltro },
        data() {
            return {
                totalRegistro: 0,
                qtdRegistro: 10,
                pagina: 0,
                firstRow: 0,
                manutencoes: [],
                filtros: {},
                manutencao: {},
                ordenar:'dataCadastro,desc',
                storange: StorageService.getControlePagina("MANUTENCAO_PESAGEM"),
            }
        },

        created() {
            this.pagina = this.storange.numero;
            this.qtdRegistro = this.storange.qtdRegistro;  
        },
        methods: {

            filtrar(filtros = {}){
                this.filtros = filtros;
                this.carregarDados();
            },

            carregarDados() {     
                ManutencaoPesagemService.getManutencoes(this.pagina, this.qtdRegistro, this.filtros, this.ordenar)
                    .then(({ data }) => {
                        if(data) {
                            this.manutencoes = data.content;
                            console.log(this.manutencoes);
                            this.qtdRegistro = data.pageable.pageSize;
                            this.totalRegistro = data.totalElements;
                        }
                    })
                    .catch(error => {
                        this.manutencoes = [];
                        this.qtdRegistro = 0;
                        this.totalRegistro = 0;
                    });
                    StorageService.setControlePagina("MANUTENCAO_PESAGEM", this.pagina, this.qtdRegistro);
            },

            sincronizar(id) {
                ManutencaoPesagemService.sincronizarComERP(id)
                    .then(() => {
                        this.$toast.add({
                            severity:"success",
                            detail: "Sincronização solicitada com sucesso.", 
                            life: 5000
                        });
                        
                    })
                    .catch(error => {
                        this.$toast.add({
                            severity:"error",
                            detail: "Erro ao tentar sincronizar.", 
                            life: 5000
                        });
                    })
                    .finally(() => {
                        //Aguarda 3 segundos para aguardar o retorno da integração.
                        setTimeout(() => { 
                            this.filtrar(this.filtros);
                        }, 3000);

                    });
            },

            onPage(event) {
                this.pagina = event.page + 1;
                this.firstRow = event.rows * event.page;
                this.qtdRegistro = event.rows;   

                this.carregarDados();
            },

            formatDate(data, format) {
                return Formatacao.formatDateCustom(data, format);
            },

            formatDecimal(value, minimumFractionDigits = 2, maximumFractionDigits = 2) {
                return Formatacao.formatDecimal(value, minimumFractionDigits, maximumFractionDigits);
            },

            sort(event) {
                const sortField = event.sortField;
                const sortOrder = event.sortOrder == 1 ? 'asc' : 'desc';
                this.ordenar = `${ sortField },${sortOrder}`;
                this.manutencoes = [];
                this.carregarDados();
            },

            getSeverity (status) {
                switch (status) {
                    case 'INTEGRADO':
                        return 'success';

                    case 'PROCESSANDO':
                        return 'warning';

                    case 'INTEGRAR':
                        return 'danger';

                    default:
                        return null;
                }
            },

            getDescricaoStatus (status) {
                switch (status) {
                    case 'INTEGRADO':
                        return 'Integrado';

                    case 'PROCESSANDO':
                        return 'Processando';

                    case 'INTEGRAR':
                        return 'Integrar';

                    default:
                        return status;
                }
            },

            getDecricaoTipo(tipo){

                switch (tipo) {
                    case 'P':
                        return 'Produção Agrícola';
                    case 'A':
                        return 'Outros';
                    case 'U':
                        return '1 Pesagem Terceiros';
                    case 'T':
                        return '2 Pesagem Terceiros';
                    default:
                        return tipo;
                }
            }
        }
    }
</script>
