<template>
    <div class="flex justify-content-between flex-column lg:flex-row">
        <div class="flex align-items-center lg:align-items-end flex-column lg:flex-row">
            <div class="mt-2 mr-2" style="min-width:15rem;">
                <label for="estabelecimento"><strong>Estabelecimento</strong></label>
                <AutoComplete
                    class="w-full mt-2"
                    id="estabelecimento"
                    placeholder="Selecionar"
                    v-model="filtros.codEstabelecimento"
                    field="label"
                    :suggestions="estabelecimentosFiltrados"
                    @item-select="selecionarEstabelecimento()"
                    :dropdown="true"
                    @complete="buscarEstabelecimento($event)"
                ></AutoComplete>
            </div>

            <div class="mt-2 mr-2">
                <label><strong>Período</strong></label>
                <div class="flex align-items-center mt-1">
                    <div class="overflow-hidden" style="width:6rem;">
                        <Calendar
                            class="w-full"
                            v-model="filtros.inicio"
                            autocomplete="off"
                            dateFormat="dd/mm/y"
                        />
                    </div>
                    <strong class="ml-2 mr-2">à</strong>
                    <div class="overflow-hidden" style="width:8.3rem;">
                        <div class="p-inputgroup">
                            <Calendar
                                class="w-full"
                                v-model="filtros.termino"
                                autocomplete="off"
                                dateFormat="dd/mm/y"
                                :minDate="filtros.inicio"
                            />
                            <Button 
                                icon="pi pi-trash"
                                style="border-radius: 0 10% 10% 0;"
                                @click="confirmarLimparPeriodo()"
                            ></Button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mt-2 mr-2">
                <Button type="button" icon="pi pi-search" label="Filtrar" class="p-button-info" @click="filtrar()"></Button>
                <Button type="button" label="Nova manutenção" icon="pi pi-plus" class="p-button-outlined ml-2" @click="abrirModal()"></Button>
            </div>
        </div>

        
    </div>

    <Dialog v-model:visible="limparPeriodoDialog" :style="{width: '300px'}" header="Alerta" :modal="true">
        <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span>Deseja limpar o filtro de período?</span>
        </div>
        <template #footer>
            <Button label="Não" icon="pi pi-times" class="p-button-text" @click="limparPeriodoDialog = false" />
            <Button label="Sim" icon="pi pi-check" class="p-button-text p-button-danger" @click="limparPeriodo()" />
        </template>
    </Dialog>

    <Dialog v-model:visible="novaManutencaoDialog" :style="{width: '80%', maxWidth: '1400px'}" header="Manutenção Ticket Pesagem" :modal="true" :closable="false">
        <Message severity="warn" v-if="alertaCamposObrigatorios" @close="alertaCamposObrigatorios = false">{{ alertaMensagem }}</Message>
        <div class="card">
            <div class="grid">

                <div class="col-4">
                    <label for="estabelecimento"><strong>Estabelecimento</strong></label>
                    <AutoComplete
                        class="w-full mt-2"
                        id="estabelecimento"
                        placeholder="Selecionar"
                        v-model="novaManutencao.codEstabelecimento"
                        field="label"
                        :suggestions="estabelecimentosFiltrados"
                        :dropdown="true"
                        @complete="buscarEstabelecimento($event)"
                        :disabled="tickets[1].length > 0"
                    ></AutoComplete>
                </div>

                <div class="mt-2 mr-2">
                    <label><strong>Período</strong></label>
                    <div class="flex align-items-center mt-1">
                        <div class="overflow-hidden" style="width:6rem;">
                            <Calendar
                                class="w-full"
                                v-model="filtrosModal.inicio"
                                autocomplete="off"
                                dateFormat="dd/mm/y"
                            />
                        </div>
                        <strong class="ml-2 mr-2">à</strong>
                        <div class="overflow-hidden" style="width:8.3rem;">
                            <div class="p-inputgroup">
                                <Calendar
                                    class="w-full"
                                    v-model="filtrosModal.termino"
                                    autocomplete="off"
                                    dateFormat="dd/mm/y"
                                    :minDate="filtrosModal.inicio"
                                />
                                <Button 
                                    icon="pi pi-trash"
                                    style="border-radius: 0 10% 10% 0;"
                                    @click="limparPeriodoModal()"
                                ></Button>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="col-2">
                    <label><strong>Ticket</strong></label>
                    <div class="flex align-items-center mt-1">
                        <div class="overflow-hidden w-full mt-1" style="width:6rem;">
                            <InputNumber v-model="filtrosModal.nroDocPesagemInicio" />
                        </div>
                        <strong class="ml-2 mr-2">à</strong>
                        <div class="overflow-hidden w-full mt-1" style="width:6rem;">
                            <InputNumber v-model="filtrosModal.nroDocPesagemFim" :min="filtrosModal.nroDocPesagemInicio" />
                        </div>
                    </div>
                </div>

                <div class="col-2">
                    <Button type="button" icon="pi pi-search" label="Localizar" class="p-button-info mt-4" @click="listarPesagens()"></Button>
                </div>

                <div class="col-12" style="border: solid 1px #cecece; border-radius:5px;">
                    <div class="col-12 flex align-items-center mt-1">
                        <div class="col-6 shadow-2 p-3" style="background-color: #f8f8f8">
                            <strong class="mr-2">Tipo de Pesagem:</strong>
                            <RadioButton class="mr-1" value="true" v-model="novaManutencao.filtrarProducaoAgricola" :disabled="tickets[1].length > 0"/>
                            <label>Produção Agrícola</label>

                            <RadioButton class="ml-5 mr-1" value="false" v-model="novaManutencao.filtrarProducaoAgricola" :disabled="tickets[1].length > 0"/>
                            <label>Outros</label>
                        </div>
                        
                        <div class="col-6 shadow-2 p-3" style="background-color: #f8f8f8">
                            <strong class="mr-2">Tipo de Manutenção:</strong>
                            <RadioButton class="mr-1" value="ALTERAR_TIPO" v-model="novaManutencao.tipoManutencao"/>
                            <label >Alterar Tipo</label>
                        
                            <RadioButton class="ml-5 mr-1" value="RE_GERADO" v-model="novaManutencao.tipoManutencao"/>
                            <label>Marcar Como RE Gerado</label>
                        </div>
                    </div>
                    <PickList v-model="tickets" dataKey="nroDocPesagem" listStyle="height:300px;">
                        <template #sourceheader>
                            Tickets Disponíveis
                        </template>
                        <template #targetheader>
                            Tickets Selecionados
                        </template>
                        <template #item="{ item }">
                            <div class="flex" style="border: solid 1px #cecece; border-radius:5px; height: 100%;">
                                <div class="col-2 mt-0"><span class="font-bold">{{ item.nroDocPesagem }}</span></div>

                                <div class="flex-1 mt-2 flex flex-column gap-2">
                                    <span class="font-bold">{{ item.codigoNomeProdutor }}</span>
                                    <div class="flex align-items-center mt-2 gap-2">
                                        <i class="pi pi-check-square mr-2"/>
                                        <span>{{ getDecricaoTipo(item.tipoPesagem) }}</span>
                                    </div>
                                    <div class="flex align-items-center mt-1 gap-2">
                                        <i class="pi pi-calendar mr-2"/>
                                        <span>{{ formatDate(item.data, 'DD/MM/YYYY')  }}</span>
                                    </div>
                                    <div class="flex align-items-center mt-1 gap-2">
                                        <i class="pi pi-car mr-2"/>
                                        <span>{{ getDecricaoTipo(item.placa) }}</span>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </PickList>

                    <Paginator
                        v-model:first="firstRowModal"
                        :rows="qtdRegistroModal"
                        :total-records="totalRegistroModal"
                        :rowsPerPageOptions="[10,50,100,200,300]"
                        @page="onPageModal"/>
                </div>


                <div class="col-2" v-if="novaManutencao.tipoManutencao == 'ALTERAR_TIPO'">
                    <label for="tipoPesagem"><strong>Novo Tipo de Pesagem <span style="color: red;">*</span></strong></label>
                    <Dropdown
                        class="w-full mt-2"
                        id="tipoPesagem"
                        optionLabel="label"
                        placeholder="Informe o novo tipo"
                        v-model="novaManutencao.novoTipoPesagem"
                        :options="tipoPesagemOpcoes"
                    />
                </div>

                <div :class="novaManutencao.tipoManutencao == 'ALTERAR_TIPO' ? 'col-10 mt-1' : 'col-12 mt-1'">
                    <label for="nome"><strong>Informe o Motivo da Manutenção<span style="color: red;">&nbsp;*</span></strong></label>
                    <Textarea :class="['w-full', 'mt-1', {'p-invalid': observacaoIsInvalid}]" id="nome" v-model="novaManutencao.observacao" rows="1" />
                </div>
                
                <div class="col-4 flex align-items-center">
                    <Button label="Salvar" icon="pi pi-save" class="p-button p-component p-button-info" @click="salvarManutencao()" :disabled="doubleClick"/>
                    <Button label="Cancelar" icon="pi pi-times" class="p-button p-component p-button-danger ml-2" @click="cancelarManutencao()" />
                </div>
                
            </div>
        </div>
    </Dialog>
    
</template>

<script>
    import ManutencaoPesagemService from '../../service/ManutencaoPesagemService';
    import EstabelecimentoService from '../../service/EstabelecimentoService';
    import Formatacao from '../../utilities/Formatacao';
    import StorageService from '../../service/StorageService';
    import parametroEstabelecimentoService from "@/service/ParametroEstabelecimentoService";

    export default {
        emits: ['filtrar'],
        components: { },
        props: {
            totalRegistro: {
                type: Number,
                required: true
            },
            situacaoDesabilitada: {
                type: Boolean,
                required: false
            }
        },
        data() {
            return {                
                totalRegistroModal: 0,
                qtdRegistroModal: 100,
                paginaModal: 0,
                firstRowModal: 0,
                doubleClick: false,
                codEstablecimento: null,
                limparPeriodoDialog: false,
                novaManutencaoDialog: false,
                filtrosModal: {},
                filtros: {},
                tickets: [[],[]],
                observacaoIsInvalid: false,
                novaManutencao:{},
                estabelecimentos: [],
                estabelecimentosFiltrados: [],
                alertaMensagem: "",
                alertaCamposObrigatorios: false,
                tipoPesagemOpcoes: [
                    { label: 'Produção Agrícola', value: 'P' },
                    { label: 'Outros Integrada', value: 'A' },
                    { label: '1 Pesagem Terceiros', value: 'U' },
                    { label: '2 Pesagens Terceiros', value: 'T' }
                ],
                storange: StorageService.getControlePagina("MANUTENCAO_PESAGEM_MODAL"),
                estabelecimentoFiltroDinamico: StorageService.getEstabelecimento(),
                usuario: StorageService.getUser()
            }
        },
        created() {

            let usuario = StorageService.getUser();

            if (!!this.estabelecimentoFiltroDinamico) {
              let estabelecimento = this.estabelecimentoFiltroDinamico;
              estabelecimento["codigoNomeFantasia"] = estabelecimento.codigo + " - " + estabelecimento.nomeFantasia;
              this.estabelecimento = estabelecimento;
              this.codEstablecimento = this.estabelecimentoFiltroDinamico.codigo;
            }

            this.limparFiltro();
            this.carregarEstabelecimentos();
        },
        methods: {

            carregarEstapelecimmentoNoFiltroDinamico() {
                const filtroDinamico = {};
                filtroDinamico["codigo"] = this.filtros.codEstabelecimento["codigo"];
                filtroDinamico["codigoRegional"] = this.filtros.codEstabelecimento["codigoRegional"];
                filtroDinamico["nomeFantasia"] = this.filtros.codEstabelecimento["nomeFantasia"];              
                filtroDinamico["razaoSocial"] = this.filtros.codEstabelecimento["razaoSocial"];
                StorageService.setEstabelecimento(filtroDinamico);
            },

            filtrar() {
                const filtros= this.getFiltros();
                this.$emit('filtrar', filtros);
                this.carregarEstapelecimmentoNoFiltroDinamico();
            },

            carregarEstabelecimentos() {
                EstabelecimentoService.buscarPorUsuarioAutenticadoComHierarquiaEstabelecimentoQueSejaSilo(undefined)
                    .then(({ data }) => {
                        this.estabelecimentos = data.map(item => {
                            item["label"] = `${ item.codigo } - ${ item.nomeFantasia}`;
                            item["codEstabelecimento"] = item.codigo;
                            return item;
                        });

                        if(!!this.estabelecimentoFiltroDinamico) {
                            let estabelecimento = this.estabelecimentos.find(x => x.codigo === this.estabelecimentoFiltroDinamico.codigo);
                            if(!!estabelecimento) this.filtros.codEstabelecimento = estabelecimento;
                        }
                    });
            },

            getFiltros() {
                const filtros = Object.assign({}, this.filtros);

                if(filtros.codEstabelecimento) {
                    filtros.codEstabelecimento = filtros.codEstabelecimento.codigo;
                }

                if(filtros.inicio) {
                    filtros.inicio = Formatacao.formatDateCustom(filtros.inicio, 'DD/MM/YYYY');
                }

                if(filtros.termino) {
                    filtros.termino = Formatacao.formatDateCustom(filtros.termino, 'DD/MM/YYYY');
                }
                return filtros;
            },

            confirmarLimparPeriodo() {
                this.limparPeriodoDialog = true;
            },

            abrirModal(){
                this.novaManutencao = {};
                this.novaManutencao.codEstabelecimento = this.filtros.codEstabelecimento;
                this.novaManutencao.tipoManutencao = "ALTERAR_TIPO";
                this.novaManutencao.filtrarProducaoAgricola = "true";

                this.filtrosModal.inicio = Formatacao.subtrairDias(new Date(), 10);
                this.filtrosModal.termino = new Date();

                this.filtrosModal.filtrarProducaoAgricola = "true";
                this.novaManutencaoDialog = true;
                this.doubleClick = false;
                this.tickets = [[],[]];
            },

            limparPeriodo() {

                if(this.filtros) {
                    this.filtros.inicio = null;
                    this.filtros.termino = null;
                }

                this.filtrar();
            },

            limparPeriodoModal() {

                if(this.filtrosModal) {
                    this.filtrosModal.inicio = null;
                    this.filtrosModal.termino = null;
                }

                this.filtrar();
            },

            confirmarLimparFiltro() {
                this.limparFiltroDialog = true;
            },

            limparFiltro() {
                const estabelecimento = StorageService.getEstabelecimento();
                if(!estabelecimento) return;

                estabelecimento['label'] = `${ estabelecimento.codigo } - ${ estabelecimento.nomeFantasia}`;
                this.limparFiltroDialog = false;
                this.filtros = {
                    codEstabelecimento: estabelecimento,
                    inicio: Formatacao.subtrairDias(new Date(), 10),
                    termino: new Date(),
                }

                this.filtrar();
            },

            buscarEstabelecimento(event) {
                setTimeout(() => {
                    if(!event.query.trim().length) {
                        this.estabelecimentosFiltrados = [...this.estabelecimentos];
                    }
                    else {
                        this.estabelecimentosFiltrados = this.estabelecimentos.filter((item) => {
                            return item.label.toLowerCase().startsWith(event.query.toLowerCase());
                        });
                    }
                }, 250);
            },
            salvarManutencao(){
                this.alertaCamposObrigatorios = false;
                this.doubleClick = true;

                if(this.validarCampos()){

                    const manutencao = {};
                    manutencao.codEstabelecimento = this.novaManutencao.codEstabelecimento.codigo;
                    manutencao.filtrarProducaoAgricola = this.novaManutencao.filtrarProducaoAgricola;                    
                    manutencao.observacao = this.novaManutencao.observacao;
                    manutencao.tipoManutencao = this.novaManutencao.tipoManutencao;
                    manutencao.usuario = this.$store.state.auth.user.codUsuario;

                    if(manutencao.tipoManutencao == "ALTERAR_TIPO"){
                        manutencao.novoTipoPesagem = this.novaManutencao.novoTipoPesagem.value;
                        manutencao.transacao = "GENESIS: Alteração do tipo de pesagem";
                    }else{
                        manutencao.novoTipoPesagem = null;
                        manutencao.transacao = "GENESIS: Atualizar situação para RE Gerado";
                    }    
                    manutencao["pesagens"] = this.tickets[1];

                    ManutencaoPesagemService.salvar(manutencao)
                    .then(response => {
                        this.$toast.add({
                            severity:'success',
                            detail: "Manutenção realizada com sucesso!", 
                            life: 5000
                        });
                        this.novaManutencaoDialog = false;
                        this.filtrar();
                    })
                    .catch(error => {
                        const { message} = error.response.data;
                        this.alertaMensagem = 'Atenção! '+ message;
                        this.alertaCamposObrigatorios = true;
                        this.doubleClick = false;

                    });
                }else{
                    this.doubleClick = false;
                }

            },
            validarCampos(){
                if(this.tickets[1].length == 0){                    
                    this.alertaMensagem = 'Atenção! Selecione o ticket de pesagem que deseja realizar a manutenção';
                    this.alertaCamposObrigatorios = true;
                    return false;
                }

                if(this.novaManutencao.tipoManutencao == "RE_GERADO"){
                    let bloquear = false;
                    this.tickets[1].forEach(function(ticket) {
                       if(ticket.tipoPesagem != "P"){
                            bloquear = true;                            
                       } 
                    });

                    if(bloquear){
                        this.alertaMensagem = 'Atenção! Selecione apenas pesagens do tipo produção agrícola para este tipo de manutenção.';
                        this.alertaCamposObrigatorios = true;
                        return false;
                    }
                }

                if(this.novaManutencao.tipoManutencao == "ALTERAR_TIPO" && this.novaManutencao.novoTipoPesagem == null){
                    this.alertaMensagem = 'Atenção! Selecione o novo tipo de pesagem do(s) tickect(s).';
                    this.alertaCamposObrigatorios = true;
                    return false;
                }
                return true;
            },
            cancelarManutencao(){
                this.alertaCamposObrigatorios = false;
                this.novaManutencaoDialog = false;
                this.tickets = [[], []];
            },
            listarPesagens(){

                this.alertaCamposObrigatorios = false;

                if(this.novaManutencao.filtrarProducaoAgricola == "true"){
                    this.getProducaoAgricolaPendente();
                }else{
                    this.getPesagemDiferentesDeProducaoAgricola();
                }
            },
            getProducaoAgricolaPendente(){
                const filtros = Object.assign({}, this.filtrosModal);
                filtros.codEstabelecimento = this.novaManutencao.codEstabelecimento.codigo;
                filtros.inicio = Formatacao.formatDateCustom(filtros.inicio, 'DD/MM/YYYY');
                filtros.termino = Formatacao.formatDateCustom(filtros.termino, 'DD/MM/YYYY');
                filtros.tipoManutencao = this.novaManutencao.tipoManutencao;

                ManutencaoPesagemService.getProducaoAgricolaPendente(filtros, this.paginaModal, this.qtdRegistroModal)
                    .then(({ data }) => {
                        if(data) {
                            let disponiveis = [];                            
                            if(data.content != null && data.content != undefined) {
                                disponiveis = data.content.filter(disponivel => {
                                    const item = this.tickets[1].find(selecionado => {
                                        return selecionado.nroDocPesagem === disponivel.nroDocPesagem;
                                    });
                                    return item === undefined;
                                });
                            }
                            
                            this.tickets = [disponiveis, this.tickets[1]];
                            this.qtdRegistroModal = data.pageable.pageSize;
                            this.totalRegistroModal = data.totalElements;

                            
                            if(disponiveis.length == 0){
                                this.alertaMensagem = 'Nenhum registro encontrado.';
                                this.alertaCamposObrigatorios = true;
                            }
                        }
                    })
                    .catch(error => {
                        this.tickets = [[], this.tickets[1]];
                        this.qtdRegistroModal = 0;
                        this.totalRegistroModal = 0;
                        this.alertaMensagem = error.response ? error.response.data.mensagem : error.message;
                        this.alertaCamposObrigatorios = true;
                    });
                    StorageService.setControlePagina("MANUTENCAO_PESAGEM_MODAL", this.paginaModal, this.qtdRegistroModal);
            },

            getPesagemDiferentesDeProducaoAgricola(){
                const filtros = Object.assign({}, this.filtrosModal);
                filtros.codEstabelecimento = this.novaManutencao.codEstabelecimento.codigo;
                filtros.inicio = Formatacao.formatDateCustom(filtros.inicio, 'DD/MM/YYYY');
                filtros.termino = Formatacao.formatDateCustom(filtros.termino, 'DD/MM/YYYY');

                ManutencaoPesagemService.getPesagemDiferentesDeProducaoAgricola(filtros)
                    .then(({ data }) => {
                        if(data) {
                            
                            let disponiveis = [];                            
                            if(data != null && data != undefined) {
                                disponiveis = data.filter(disponivel => {
                                    const item = this.tickets[1].find(selecionado => {
                                        return selecionado.nroDocPesagem === disponivel.nroDocPesagem;
                                    });
                                    return item === undefined;
                                });
                            }
                            
                            this.tickets = [disponiveis, this.tickets[1]];
                            this.qtdRegistroModal = 100;
                            this.totalRegistroModal = 0;

                            if(disponiveis.length == 0){
                                this.alertaMensagem = 'Nenhum registro encontrado.';
                                this.alertaCamposObrigatorios = true;
                            }
                        }
                    })
                    .catch(error => {
                        this.tickets = [[], this.tickets[1]];
                        this.qtdRegistroModal = 100;
                        this.totalRegistroModal = 0;
                        this.alertaMensagem = error.response ? error.response.data.message : error.message;
                        this.alertaCamposObrigatorios = true;
                    });
                    StorageService.setControlePagina("MANUTENCAO_PESAGEM_MODAL", this.paginaModal, this.qtdRegistroModal);
            },

            getDecricaoTipo(tipo){

                switch (tipo) {
                    case 'P':
                        return 'Produção Agrícola';
                    case 'A':
                        return 'Outros';
                    case 'U':
                        return '1 Pesagem Terceiros';
                    case 'T':
                        return '2 Pesagem Terceiros';
                    default:
                        return tipo;
                }
            },

            selecionarEstabelecimento() {
              if (this.estabelecimento == undefined || this.estabelecimento == null) { return; }

              const usuario = StorageService.getUser();
              StorageService.setEntradaProducao({ username: usuario.username, estabelecimento: this.estabelecimento });

              this.carregarEstapelecimmentoNoFiltroDinamico();
            },

            formatDate(data, format) {
                return Formatacao.formatDateCustom(data, format);
            },

            onPageModal(event) {
                this.paginaModal = event.page + 1;
                this.firstRowModal = event.rows * event.page;
                this.qtdRegistroModal = event.rows;  
                this.getProducaoAgricolaPendente();
            }
        },
    }
</script>
